/* eslint-disable */
import {
  createReturnItem,
  deactivateReturnItem,
  getReturnExport,
  getReturnList,
  getReturnItem,
  updateReturnItem,
  postReturnImport,
  getOtherStorageReturn,
  getReturnForBookingList,
} from '@/methods/return';
import { formatDate, getErrorMessage } from '@/compositions/helpers/helpers';
import { ElNotification } from 'element-plus';
import store from '@/store';
import router from '@/router';

const state = {
  list: [],
  item: {},
  currentPage: 1,
  otherStorageReturnList: [],
  filters: {
    'Статус возврата': [
      'Ожидает брони',
      'Истекает срок хранения',
      'Истек срок хранения',
      'Необходимо собрать',
      'В процессе сборки',
      'Ожидает отгрузки',
      'Частично отгружен',
    ],
  },
  dataIsUpdated: false,
};

const getters = {
  getReturnList(state) {
    return state.list;
  },
  getReturnItem(state) {
    return state.item;
  },
};

const mutations = {
  UPDATE_RETURN_LIST(state, data) {
    if (Object.values(data?.data).length > 0) {
      Object.values(data?.data).map((item) => {
        let groupedReturnBoxes = [];

        if (Object.values(item?.boxes).length > 0) {
          Object.values(item?.boxes).map((box) => {
            const name = box?.size?.name;
            const group = groupedReturnBoxes.find((group) => group.name === name);

            if (!group) {
              groupedReturnBoxes.push({
                name,
                items: [box],
              });
            } else {
              group.items.push(box);
            }
          });
        }

        if (item.status?.id === 6) {
          let waitingBoxList = Object.values(item?.boxes).filter((box) => box.status?.id === 1);

          item['quantity'] = 0;
          item['waitingBoxListCount'] = waitingBoxList.length;

          waitingBoxList.forEach((box) => {
            item.quantity += box?.quantity;
          });
        }

        item['groupedReturnBoxes'] = groupedReturnBoxes;

        return item;
      });
    }

    state.list = data;
  },
  UPDATE_RETURN_ITEM(state, data) {
    state.item = data;

    if (state.item?.boxes) {
      state.item.boxes.map((box) => {
        box.assemblyDate = formatDate(box.assemblyDate?.date);

        return box;
      });
    }
  },
  RETURN_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
  },
  UPDATE_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
  UPDATE_OTHER_STORAGE_RETURN_LIST(state, data) {
    state.otherStorageReturnList = data;
  },
};

const actions = {
  getReturnList({ commit, state }, params) {
    getReturnList(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_RETURN_LIST', response.data.data);
          commit('privilege/UPDATE_RETURN_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },
  getReturnForBookingList({ commit, state }, params) {
    getReturnForBookingList(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          let resultData = {
            data: response.data.data,
          };

          commit('UPDATE_RETURN_LIST', resultData);
          commit('privilege/UPDATE_RETURN_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  getReturnItem({ commit, state }, params) {
    getReturnItem(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_RETURN_ITEM', response.data.data);
      }
    });
  },

  getReturnExport({ commit, state }, params) {
    getReturnExport(params).then((response) => {
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', response.data]);

      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `return-data-${formatDate(new Date())}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  },

  postReturnImport({ commit, dispatch, state }, params) {
    postReturnImport(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('RETURN_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  createReturnItem({ commit, dispatch, state }, params) {
    createReturnItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('RETURN_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateReturnItem({ commit, dispatch, state }, { id, data }) {
    updateReturnItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('RETURN_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deactivateReturnItem({ commit, dispatch, state }, { id, data }) {
    deactivateReturnItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('RETURN_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  getOtherStorageReturn({ commit, state }, params) {
    getOtherStorageReturn(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_OTHER_STORAGE_RETURN_LIST', response.data.data);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
